@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
    
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

input[type="date"]::-webkit-calendar-picker-indicator {
  filter: invert(66%) sepia(7%) saturate(314%) hue-rotate(219deg) brightness(88%) contrast(84%);
}

input[type="date"] {
  color:#ffffff80;
}

#custom-slider::-webkit-slider-thumb {
  width: 40px;
  height: 25px;
  background: #41414d;
  border: 1px solid #FFFFFF99;
  border-radius: 0.25rem;
  -webkit-appearance: none;
  margin-top: 0px; 
}
.blur-box {
  background: linear-gradient(
    to bottom,
    #c16ef412,
    #c16ef402,
    #c16ef42d,
    #c16ef423
    
  );
  /* background-image:
    radial-gradient(
      circle at bottom right,
      #9e03ffca,
      #8f10df02
    ); */
  opacity: 0.8;
  transform: rotate(0deg);
}


.custom-scroll::-webkit-scrollbar {
  height: 0.2em;
  width: 0.6em;

}

.custom-scroll::-webkit-scrollbar-track {
  /* box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3); */
  background-color:transparent;
  outline: none;
}

.custom-scroll::-webkit-scrollbar-thumb {
  background:linear-gradient(
    to right,
    #ffffff52,
    #ffffff00
    
  );
  outline: 1px solid #333333;
  border-radius: 2px;
  padding-top: 10px;
}


textarea:focus,
option:focus,
select:focus,
input:focus {
  outline: none;
}
