.Dashboard {
  background: linear-gradient(166.98deg,
      rgba(129, 140, 238, 0.9) 9.4%,
      rgba(20, 20, 29, 1) 25.97%,
      rgba(20, 20, 29, 1) 44.72%);
}

.dashboardLeft {
  background-color: #14141d;
  text-align: center;
  min-height: 100vh;
  /* Full-height: remove this if you want "auto" height */
  position: fixed;
  /* Fixed Sidebar (stay in place on scroll) */
  z-index: 1;
  /* Stay on top */
  top: 0;
  /* Stay at the top */
  left: 0;
  overflow-x: hidden;
  border-top-right-radius: 40px;
}

.userImage {
  width: 100px;
  height: 100px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  border-radius: 50%;
  color: #f2f2f2;
  font-size: 3.3rem;
  align-items: center;
  text-align: center;
}

.username {
  font-family: "Poppins", "Work Sans", "Helvetica";
  font-style: normal;
  font-weight: 500;
  font-size: 24px;
  line-height: 40px;
  color: #f2f2f2;
  margin-top: 20px;
}

.userEmail {
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  margin-top: -5px;
}

.sideTab {
  /* font-family: "Poppins", "Work Sans", "Helvetica"; */
}

.sideTabActive {
  /* font-family: "Poppins", "Work Sans", "Helvetica"; */

  border: 1px solid;

  border-image-source: linear-gradient(267.61deg, rgba(129, 140, 238, 0.18) 6.95%, rgba(255, 255, 255, 0.092) 33.01%, rgba(129, 140, 238, 0.02) 57.21%);



}
.skillsDouble{
  /* Old browsers */
  background: linear-gradient(to right, #818CEE00, #818CEE33);
  background-size: 200% 100%;
  background-position:right bottom;
  margin-left:10px;
  transition:all 2s ease;
}
.skillsDouble:hover {
  background-position:left bottom;
}

.animateLeft {
  background: transparent;
  background-position: right bottom;
  transition: all .5s ease-out;
}

.animateLeft:hover {
  background: linear-gradient(to right, #818CEE00, #818CEE33);
  background-position: left bottom;
}

/* trade history css */

.investedBox {
  border: 2px solid;

  border-image-slice: 1;




  border-image-source: linear-gradient(166.98deg, rgba(129, 140, 238, 0.9) 9.4%, rgba(255, 255, 255, 0.456917) 25.97%, rgba(129, 140, 238, 0.1) 44.72%);

  border-radius: 60px;
}

.InvestedHead {
  font-family: "Poppins", "Work Sans", "Helvetica";
  font-style: normal;
  font-weight: 300;
  font-size: 20px;
  line-height: 22px;
  color: rgba(255, 255, 255, 0.8);
}

.InvestedAmount {
  font-family: "Poppins", "Work Sans", "Helvetica";
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 32px;
  color: #ffffff;
  margin-top: 1rem;
}

.dataContent {
  font-family: "Poppins", "Work Sans", "Helvetica";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 22px;
  color: #f2f2f2;
  padding: 10px;
  width: 12%;
}

.greenColorText {
  color: #18b13f;
}

.redColorText {
  color: #eb323f;
}

.InvestedHeadText {
  font-family: "Poppins", "Work Sans", "Helvetica";
  font-style: normal;
  font-weight: 500;
  font-size: 22px;
  line-height: 22px;
  color: rgba(255, 255, 255, 0.8);
  margin-top: 30px;
}

.tradeDiv {
  display: flex;
  background: #17181b;
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-radius: 8px;
  margin-top: 20px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  border-bottom: none;
  margin-right: 35px;
}

.maptradeData {
  display: flex;
  border: 1px solid rgba(255, 255, 255, 0.2);
  border-top: none;
  margin-right: 35px;
}

.dataHeading {
  font-family: "Poppins", "Work Sans", "Helvetica";
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 22px;
  color: #f2c94c;
  padding: 10px;
  width: 12%;
}

.dataHeading2 {
  font-family: "Poppins", "Work Sans", "Helvetica";
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 22px;
  color: #f2c94c;
  padding: 10px;
  width: 17%;
}

.dataHeading3 {
  font-family: "Poppins", "Work Sans", "Helvetica";
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  line-height: 22px;
  color: #f2c94c;
  padding: 10px;
  width: 32%;
}

.dataContent2 {
  font-family: "Poppins", "Work Sans", "Helvetica";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 22px;
  color: #f2f2f2;
  padding: 10px;
  width: 17%;
}

.dataContent3 {
  font-family: "Poppins", "Work Sans", "Helvetica";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  line-height: 22px;
  color: #f2f2f2;
  padding: 10px;
  width: 32%;
}

/* Exchange Integrate css */
.exchangeDiv {
  width: 80%;
  padding: 6.5rem;
  padding-top: 10rem;

  background-repeat: no-repeat;
  background-position: center;
  margin-left: 20%;
}

.exchnageHeading {
  font-family: "Roboto", "Work Sans", "Helvetica";
  font-style: normal;
  font-weight: 500;
  font-size: 30px;
  line-height: 40px;
  color: #f2f2f2;
}

.exchnageDataName {
  width: 33%;
  display: flex;
  align-items: center;
  font-family: "Poppins", "Work Sans", "Helvetica";
  font-style: normal;
  font-weight: 500;
  font-size: 26px;
  line-height: 50px;
  color: #ffffff;
  margin-top: 30px;
}

.exchngaeImg {
  width: 40px;
  height: 40px;
  margin-right: 15px;
}

.btcText {
  font-family: "Poppins", "Work Sans", "Helvetica";
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 22px;
  color: #ffffff;
  margin-left: 10px;
}

.dropdownBox {
  display: flex;
  justify-content: space-between;
  align-items: center;
  text-align: left;
  height: 48px;
  border-bottom: 1px solid #FFFFFF1F;
  /* border-radius: 4px; */
  /* background: none; */
}

.dropdownBox2 {
  border: 1px solid #FFFFFF1F;
  border-radius: 4px;
}

.dropdownBoxSelect {
  background: #17181b;
  border-radius: 4px;
  background: none;
  font-family: "Roboto", "Work Sans", "Helvetica";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  cursor: pointer;
  width: 100%;
}

.dropDownSelection {
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
  cursor: pointer;
}

.inputDate {
  box-sizing: border-box;
  align-items: center;
  height: 48px;
  border-bottom: 1px solid #FFFFFF1F;
  background: none;
  /* border-radius: 4px; */
  color: #f2f2f2;
  font-size: 14px;
  font-family: "Roboto", "Work Sans", "Helvetica";
}

.inputDate::-webkit-calendar-picker-indicator {
  filter: invert(1);
}

.leftSideBottomContent {
  color: #ffcf2c;
  position: absolute;
  bottom: 15px;
  border-top: 2px solid rgba(255, 255, 255, 0.08);
  font-family: "Poppins", "Work Sans", "Helvetica";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 21px;
  width: 100%;
  padding-top: 15px;
}

.imagecss {
  align-items: center;
  text-align: center;
}

/* table  css*/

.table {
  border-collapse: collapse;
  margin-left: 0px;
  padding: 0;
  width: 100%;
  table-layout: fixed;
}

.table td {
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  padding: 10px 20px;
  /* color: rgba(255, 255, 255, 0.85); */
  font-size: 13px;
  font-family: "Poppins", sans-serif;
}

.table th {
  font-size: 13px;
  padding: 10px 20px;
  font-weight: 300;
  text-transform: uppercase;
  color: rgba(255, 255, 255, 0.7);
  border-bottom: none;
  text-align: left;
  font-family: "Poppins", sans-serif;
  background-color: #14141d;
}

@media screen and (max-width: 700px) {
  .table {
    display: inline-block;
    overflow-x: auto;
    white-space: nowrap;
    max-width: 100%;
    margin: auto;
    border: 0;
  }

  .table::-webkit-scrollbar {
    display: none;
  }
}