/* .mt60 {
  background-image: url("../assests/LoginBackground.svg");
} */


.innerShadow {
  /* box-shadow: -20.266666412353516px 20.266666412353516px 20.266666412353516px 0px #FFFFFF1A inset;
  box-shadow: 20.266666412353516px -20.266666412353516px 20.266666412353516px 0px #C2C2C21A inset; */
  /* box-shadow: inset 20px 20px 20px 0px rgba(255, 255, 255, 0.06); */
  /* box-shadow: inset 0px 20px 20px 0px rgba(255, 255, 255, 0.06); */
  box-shadow: 0 0 20px 20px rgba(255, 255, 255, 0.06) inset;
  /* opacity: 80%; */
  /* border-image-slice: 1;
  border-image-source: linear-gradient(to right, rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0.3), #8bd89525, #8BD89599);
  background-origin: border-box;
   background-clip: padding-box,border-box; */

}

.linearGradient {
  background-image: linear-gradient(to right, rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0.3), #8bd89525, #8bd89581);
  ;
}

.logininput {
  align-items: center;
  padding: 8px 12px;
  width: 100%;
  height: 48px;
  background: none;
  border-radius: 6px;
  color: #ffffff;
  font-size: 16px;
  font-family: "Poppins", "Work Sans", "Helvetica";
}

.loginDiv {
  margin-top: 40px;
  display: flex;
  align-items: center;
  width: 100%;
}

.loginDiv1 {
  margin-top: 20px;
  display: flex;
  align-items: center;
  width: 100%;
}

.usericons {
  height: 48px;
  align-items: center;
  padding-left: 10px;
  font-size: 28px;
}

.forgotText {
  font-family: "Poppins", "Work Sans", "Helvetica";
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 22px;
}

.loginButton {
  justify-content: center;
  align-items: center;
  padding: 8px 16px;
  gap: 8px;
  width: 100%;
  height: 48px;
  background: #e43d3d;
  box-shadow: 0px 2px 0px rgba(0, 0, 0, 0.043);
  border-radius: 26px;
  font-family: "Poppins", "Work Sans", "Helvetica";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 22px;
  text-align: center;
  color: #ffffff;
  margin-top: 20px;
}

.SignUpHeaad {
  font-family: "Poppins", "Work Sans", "Helvetica";
  font-style: normal;
  font-weight: 500;
  font-size: 28px;
  line-height: 24px;
  color: #ffffff;
  margin-top: 35px;
}

.resetPasswordSection {
  padding: 130px;
  text-align: center;
  align-items: center;
}

.resetloginBackground {
  background: linear-gradient(171.22deg,
      #191a1d 46.86%,
      rgba(116, 99, 44, 0.06) 175.21%);
  border-radius: 10px;
  padding: 50px;
  width: 50%;
  display: block;
  margin-left: auto;
  margin-right: auto;
  align-items: center;
}

.resetDiv {
  text-align: center;
  width: 100%;
  margin-left: 20px;
}

